<template>
  <InfoContainer info="privacyPolicy"></InfoContainer>
</template>

<script>
  import InfoContainer from '@/components/info/InfoContainer';
  import { i18nHelper } from '@/utils';

  export default {
    name: 'PrivacyPolicy',
    metaInfo: {
      title: i18nHelper.getMessage('meta.title.privacyPolicy'),
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: i18nHelper.getMessage('meta.description.privacyPolicy')
        }
      ]
    },
    components: {
      InfoContainer
    }
  };
</script>

<style lang="scss"></style>
